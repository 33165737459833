<template>
  <div v-loading="isLoading" class="classify-page xa-view xa-container">
    <div class="page-main xa-flex xa-container">
      <el-cascader-panel
        ref="cascader"
        :options="options"
        :props="{ multiple: true }"
        v-model="values"
      ></el-cascader-panel>
    </div>
    <div class="page-btns xa-cell">
      <div style="margin-right:16px;" v-if="values.length">
        已选
        <span class="xa-txt-blue">({{ values.length }})</span>个分类
      </div>
      <el-button size="small" @click="onClose">取消</el-button>
      <el-button
        type="primary"
        size="small"
        @click="onSubimt"
        :disabled="values.length == 0"
        >确定</el-button
      >
    </div>
  </div>
</template>
<script>
import { getCategoryListOptions } from '@target/apis/coupon'
export default {
  data() {
    return {
      isLoading: true,
      options: [],
      values: [],
    }
  },
  props: {
    params: {
      //查询参数
      type: Object,
      default() {
        return {}
      },
    },
  },
  methods: {
    onSubimt() {
      const nodes = this.$refs.cascader.getCheckedNodes()
      let guid2Name = {}
      nodes.forEach((node) => (guid2Name[node.value] = node.label))
      const results = this.values.map((items) => {
        return {
          name: items.map((guid) => guid2Name[guid]),
          guid: items,
        }
      })
      this.$refs.cascader.clearCheckedNodes()
      this.$emit('submit', results)
    },
    onClose() {
      this.$emit('close')
    },
  },
  async created() {
    try {
      this.options = await getCategoryListOptions(this.params)
    } finally {
      this.isLoading = false
    }
  },
}
</script>
<style lang="scss" scoped>
.classify-page {
  height: 530px;
  /deep/ .el-cascader-panel {
    height: 100%;
  }
  /deep/ .el-cascader-menu__wrap {
    overflow-x: hidden;
    height: 100%;
  }
}
.page-btns {
  flex-shrink: 0;
  padding: 8px 0;
  justify-content: flex-end;
}
</style>
